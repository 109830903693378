// Anchor link scroll
$(function () {
    const urlHash = location.hash;
    if (urlHash) {
        $("body,html").stop().scrollTop(0);
        setTimeout(function () {
            const isPc = window.innerWidth > 560;
            const offsetAnchor = isPc ? "300" : "100";
            const target = $(urlHash);
            const position = target.offset().top - offsetAnchor;
            $("body,html").stop().animate({ scrollTop: position }, 0);
        }, 100);
    }
    $('a[href^="#"]').on("click", function () {
        const isPc = window.innerWidth > 560;
        const offsetAnchor = isPc ? "300" : "100";
        const href = $(this).attr("href");
        const target = $(href);
        const position = target.offset().top - offsetAnchor;
        $("body,html").stop().animate({ scrollTop: position }, 0);
        return false;
    });
});