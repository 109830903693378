const hero = document.getElementById("hero");
const floatVertical = document.getElementById("float_vertical");
const floatHorizon = document.getElementById("float_horizon");
const toTop = document.getElementById("toTop")

const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
};

const callback = (entries, observer) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            floatVertical.classList.remove("right-0");
            floatVertical.classList.add("-right-96");
            floatHorizon.classList.remove("bottom-0");
            floatHorizon.classList.add("-bottom-96");
            toTop.classList.add("opacity-0")
        } else {
            floatVertical.classList.remove("-right-96", "opacity-0");
            floatVertical.classList.add("right-0", "opacity-100");
            floatHorizon.classList.add("bottom-0", "opacity-100");
            floatHorizon.classList.remove("-bottom-96", "opacity-0");
            toTop.classList.remove("opacity-0")
        }
    });
};

const observer = new IntersectionObserver(callback, options);
hero && observer.observe(hero);
