jQuery(function () {
    jQuery(".courceBox,.columnTit").matchHeight();
    jQuery(
        ".otherLinkBox,.courceBox,.column,.columnArticle,#archives .archive"
    ).click(function () {
        window.location = jQuery(this).find("a").attr("href");
        return false;
    });
});

/*------------------------------------------

  ウィンドウサイズでクラスの追加

------------------------------------------*/
jQuery(window).resize(function () {
    var w = jQuery(window).width();
    var x = 768;
    if (w <= x) {
        jQuery("body").addClass("sp");
        jQuery("body").removeClass("pc");
    } else {
        jQuery("body").addClass("pc");
        jQuery("body").removeClass("sp");
    }
});

jQuery(function () {
    var w = jQuery(window).width();
    var x = 768;
    if (w <= x) {
        jQuery("body").addClass("sp");
        jQuery("body").removeClass("pc");
    } else {
        jQuery("body").addClass("pc");
        jQuery("body").removeClass("sp");
    }

    /*------------------------------------------

  ページトップ

  ------------------------------------------*/
    //html 要素でスクロールするか試す
    // var isHtmlScroll = (function () {
    //     var html = jQuery("html"),
    //         top = html.scrollTop();
    //     var el = jQuery("<div/>").height(10000).prependTo("body");
    //     html.scrollTop(10000);
    //     var rs = !!html.scrollTop();
    //     html.scrollTop(top);
    //     el.remove();
    //     return rs;
    // })();

    // // PageTopヘッダ分ずらす
    // var followH = jQuery("#followHeader").height();
    // var headH = followH + 30;

    // jQuery("#pageTop a,.pageLink a").click(function () {
    //     var h = jQuery(this).attr("href");
    //     var y = jQuery(h == "#" || h == "" ? "html" : h).offset().top;
    //     //スムーススクロール」
    //     jQuery(isHtmlScroll ? "html" : "body").animate(
    //         { scrollTop: y - headH + "px" },
    //         1000,
    //         "swing"
    //     );
    //     return false;
    // });
    // var topBtn = jQuery("#pageTop");
    // topBtn.hide();
    // jQuery(window).scroll(function () {
    //     if (jQuery(this).scrollTop() > 100) {
    //         topBtn.fadeIn();
    //     } else {
    //         topBtn.fadeOut();
    //     }
    // });

    // if (location.hash) {
    //     var hash = location.hash;
    //     window.scroll(0, headH);
    //     jQuery("a[href=" + hash + "]").click();
    // }

    /*------------------------------------------

  ハイライト設置(SP)

  ------------------------------------------*/
    jQuery(".touch").bind({
        touchstart: function () {
            jQuery(this).removeClass("notouchstyle").addClass("touchstyle");
        },
        touchend: function () {
            jQuery(this).removeClass("touchstyle").addClass("notouchstyle");
        },
    });
});
