import Swiper, { Autoplay, EffectFade, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

const swiperHero = new Swiper(".swiper_hero", {
    modules: [Autoplay, EffectFade],
    loop: true,
    autoplay: {
        delay: 4000,
        disableOnInteraction: false,
    },
    speed: 4000,
    slidesPerView: 1,
    effect: "fade",
    fadeEffect: {
        crossFade: true,
    },
});

const swiperGraduate = new Swiper(".swiper_graduate", {
    modules: [Autoplay, Pagination],
    loop: true,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
    },
    centeredSlides: true,
    pagination: {
        el: ".swiper-pagination",
        type: "bullets",
    },
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1.4,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2.6,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 3.4,
            spaceBetween: 40,
        },
        1200: {
            slidesPerView: 3.9,
            spaceBetween: 40,
        },
        1440: {
            slidesPerView: 3.8,
            spaceBetween: 40,
        },
        1536: {
            slidesPerView: 4.2,
            spaceBetween: 40,
        },
    },
});

