import IScroll from "iscroll";
import { speed } from "jquery";
import $ from "jquery";
import "jquery-drawer";
import "jquery-drawer/dist/css/drawer.css";

const drawer_icon = document.getElementById("drawer_icon");

$(document).ready(function () {
    $(".drawer").drawer({
        iscroll: {
            mouseWheel: false,
        },
    });
});

$(".drawer").on("drawer.opened", () => {
    drawer_icon.innerHTML = '<i class="fa-solid fa-xmark"></i>';
});

$(".drawer").on("drawer.closed", () => {
    drawer_icon.innerHTML = '<i class="fa-solid fa-bars"></i>';
});

$("#course_body").hide();
$(document).on("click", "#course_navigation", () => {
    $("#course_body").slideToggle("fast");
});

$("#about_body").hide();
$(document).on("click", "#about_navigation", () => {
    $("#about_body").slideToggle("fast");
});
