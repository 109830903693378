import Swiper, { Autoplay, EffectFade, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

const fetcher = async (course) => {
    const res = await fetch(
        `https://script.google.com/macros/s/AKfycbwegCfYTvaGqRf9OFVhV05a_xvTmSj7rTo2vAPeHTIU4AugOu1yiJzWznIsHoG-E6A5/exec?course=${course}`
    );
    const json = await res.json();
    return json;
};

const getPath = () => window.location.pathname;

const getCurrentCourse = (path) => {
    if (path.includes("kookaku")) {
        return "kookaku";
    }
    if (path.includes("kaotype")) {
        return "kaotype";
    }
    if (path.includes("makeup")) {
        return "makeup";
    }
    if (path.includes("stylist")) {
        return "personalStylist";
    }
    if (path.includes("eight_color")) {
        return "eightColor";
    }
};

const fetchGas = async () => {
    const path = getPath();
    const course = getCurrentCourse(path);
    if (!course) return;

    const json = await fetcher(course);
    return formatJson(json);
};

const formatJson = (json) => {
    const returnArray = [];
    const areaRegex =
        /協会本部開催（東京） | 北海道| 東北| 関東| 甲信越| 北陸| 東海| 関西| 中国| 四国| 九州| 沖縄| 海外| オンライン | 通信教育/;

    json.map((j) => {
        j.area.match(areaRegex);
        returnArray.push(j);
    });

    return returnArray;
};

const inserter = async () => {
    const arr = await fetchGas();

    const teachersArea = document.getElementById("teachers_area");
    const teachersAreaSp = document.getElementById("teachers_area_sp");
    const teachersHeadingArea = document.getElementById("teachers_heading");
    const teachersText = document.getElementById("teachers_text");
    if(!teachersArea) return 
    
    if (arr.length === 0) {
        teachersText.innerHTML = "<div class='md:-mb-48 lg:-mb-40'></div>";
        return;
    }

    const teacherHeading = `<div style="background: linear-gradient(0deg, #fde8e3 0%, rgba(218, 166, 188, 0.3) 50%, #fde8e3 100%);" class="heading_primary text-center bg-pink py-8">
        <h2 class="-mt-8 leading-relaxed tracking-wider">
            <?php echo $title ?><br />主な講師紹介
        </h2>
        <p class="smaller">#Teachers</p>
    </div>`;

    let teacherBody = "";
    let teacherBodySp = "";
    let teacherBodySpStart = "<ul class='grid grid-cols-2 gap-4 my-8'>";
    let teacherBodySpEnd = "</ul>";

    const new_arr = Array.from(new Map(arr.map((a) => [a.name, a])).values());
    const isSwiperOff = new_arr.length < 4;

    new_arr.map((a, i) => {
        if (i > 9) return;

        teacherBody += `<div class="swiper-slide flex h-auto">
        <div class="relative max-w-[290px] h-full mx-auto">
            <img class="relative z-[2] aspect-[3/4] object-cover w-full h-full" src="${a.img}" alt="">
            <div class="bg-ctaPink w-full h-full absolute top-4 -right-4"></div>
            <p class="absolute -bottom-8 -right-8 bg-white px-8 py-4 text-base lg:text-lg font-semibold leading-relaxed lg:leading-relaxed z-[3]">
                ${a.name}<br />
                <span class='font-light text-sm'>担当エリア：${a.area}</span>
            </p>
        </div>
    </div>`;

        teacherBodySp += `<li class="text-center">    
            <img class='aspect-[3/4] w-full object-center object-cover mb-4' src='${a.img}' alt="">
            <p class="text-lg font-semibold leading-normal">
                ${a.name}<br />
                <span class="text-sm font-light">${a.area}</span>
            </p>
        </li>
        `;
    });

    teachersArea.innerHTML = teacherBody;
    teachersAreaSp.innerHTML = teacherBodySpStart + teacherBodySp + teacherBodySpEnd;
    teachersHeadingArea.innerHTML = teacherHeading;
    teachersText.innerHTML = "";

    new Swiper(".swiper_teacher", {
        modules: [Autoplay, Pagination],
        loop: true,
        autoplay: isSwiperOff
            ? false
            : {
                  delay: 3000,
                  disableOnInteraction: false,
              },
        centeredSlides: true,
        pagination: {
            el: ".swiper-pagination",
            type: "bullets",
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1.4,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: isSwiperOff ? new_arr.length : 2.6,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: isSwiperOff ? new_arr.length : 3.4,
                spaceBetween: 40,
            },
            1200: {
                slidesPerView: isSwiperOff ? new_arr.length : 3.9,
                spaceBetween: 40,
            },
            1440: {
                slidesPerView: isSwiperOff ? new_arr.length : 3.8,
                spaceBetween: 40,
            },
            1536: {
                slidesPerView: isSwiperOff ? new_arr.length : 4.2,
                spaceBetween: 40,
            },
        },
    });
};

inserter();
