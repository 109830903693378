const tabName = document.getElementById("tab_courseName");
const basicTab = document.getElementById("pills-basic-tab");
const basicBody = document.getElementById("pills-basic");
const advanceTab = document.getElementById("pills-advance-tab");
const advanceBody = document.getElementById("pills-advance");

const pathName = location.pathname;
const urlHash = location.hash;

const generateText = () => {
    if (advanceTab.classList.contains("active")) {
        return "アドバンス";
    }
    return "基礎";
};

const insertText = () => {
    const text = generateText();
    tabName.innerHTML = text;
};

if (!pathName.includes("/makeup/")) return;
if (urlHash === "#advance" || urlHash === "/") {
    basicTab.classList.remove("active");
    basicBody.classList.remove("active");
    advanceTab.classList.add("active");
    advanceBody.classList.add("active", "show");
}

insertText();

$(".nav-link").on("click", function () {
    insertText();
});
