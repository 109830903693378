const nav = document.getElementById("navigation");
const courseNav = document.getElementById("course_navigation");
const aboutNav = document.getElementById("about_navigation");
const courseNavBody = document.getElementById("course_navigation_body");
const aboutNavBody = document.getElementById("about_navigation_body");
const hero = document.getElementById("hero");

const navHeight = nav.clientHeight;
hero && (hero.style.marginTop = `${navHeight}px`);

courseNav.addEventListener("mouseover", () =>
    courseNavBody.classList.add("active")
);

courseNav.addEventListener("mouseout", () =>
    courseNavBody.classList.remove("active")
);

courseNavBody.addEventListener("mouseover", () =>
    courseNavBody.classList.add("active")
);
courseNavBody.addEventListener("mouseout", () =>
    courseNavBody.classList.remove("active")
);

aboutNav.addEventListener("mouseover", () =>
    aboutNavBody.classList.add("active")
);

aboutNav.addEventListener("mouseout", () =>
    aboutNavBody.classList.remove("active")
);

aboutNavBody.addEventListener("mouseover", () =>
    aboutNavBody.classList.add("active")
);
aboutNavBody.addEventListener("mouseout", () =>
    aboutNavBody.classList.remove("active")
);
