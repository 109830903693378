import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";

import "./js/viewport";
import "./js/swiper";
import "./js/navigation";
import "./js/drawer";
import "./js/hero";
import "./js/toTop";
import "./js/page";
import "./js/bootstrap"
import "./js/makeup"
import "./js/fetchCourse"

import "./css/tab.css"
import "./css/add.css";
import "./css/global.css";
import "./css/typography.css";
import "./css/button.css";
import "./css/decorations.css";
import "./css/articles.css";
import "./css/drawer.css";
import "./css/navigation.css";
import "./css/page.css";

import "./existing_js/common";
import "./existing_js/jquery.bgswitcher";
import "./existing_js/jquery.matchHeight-min";
import "./existing_js/original";
